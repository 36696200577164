import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import ebooksbookone from "../images/ebooks-bookone.jpg"
import ebooksbooktwo from "../images/ebooks-booktwo.jpg"
import ebooksbookthree from "../images/ebooks-bookthree.jpg"
import ebooksbookfour from "../images/ebooks-bookfour.jpg"
import ebooksbookfive from "../images/ebooks-bookfive.jpg"

const ebooks = () => {
  return (
    <Layout>
      <div className="container">
        <section className="ebooks-section">
          <h1>Take a page from our book.</h1>

          <div className="ebooks-grid">
            <Link to="/">
              <img src={ebooksbookone} alt="" />
            </Link>

            <Link to="/">
              <img src={ebooksbooktwo} alt="" />
            </Link>

            <Link to="/">
              <img src={ebooksbookthree} alt="" />
            </Link>

            <Link to="/">
              <img src={ebooksbookfour} alt="" />
            </Link>

            <Link to="/">
              <img src={ebooksbookfive} alt="" />
            </Link>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ebooks
